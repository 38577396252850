<template>
  <div class="class-list">
    <GlobalInfoBar title="回收商库存数据" />
    <GlobalChunk>
      <!-- 查询区 -->
      <div class="btnStore">
        <div class="flex" style="margin-right: 10px">
          <span style="min-width: max-content;">回收商家：</span>
          <el-select v-model="seachMerchantId" filterable clearable @change="merchanChange" placeholder="请选择"
            class="el-form-group_isRound">
            <el-option v-for="item in merchantSelectList" :key="item.merchantId" :label="item.merchantName"
              :value="item.merchantId">
            </el-option>
          </el-select>
        </div>
        <div class="time-select">
          <div class="btn" :class="dateType == '01' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('01')">
            今日
          </div>
          <div class="btn" :class="dateType == '03' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('03')">
            本周
          </div>
          <div class="btn" :class="dateType == '05' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('05')">
            本月
          </div>
        </div>
        <div>
          <el-date-picker v-model="valueTime" type="daterange" range-separator="至" value-format="timestamp"
            start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
        </div>
        <div style="margin-left: 10px">
          <el-button type="primary" @click="seachData" :loading="searchLoading" round>查询</el-button>
        </div>
      </div>
      <!-- 切换按钮 -->
      <div class="top_swith">
        <div class="button_top">
          <div class="radiobtn">
            <div class="faultBtn">
              <div v-bind:class="tabType === '01' ? 'left' : 'right'" @click="btnClick('01')">
                入库旧机
              </div>
              <div v-bind:class="tabType === '02' ? 'left2' : 'right'" @click="btnClick('02')">
                收件包裹
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 表格区 入库旧机-->
      <div v-if="tabType === '01'">
        <el-table :default-sort="{prop:'onHouseFxDefaultNum',order:'descending'}" @sort-change="sortTable" key="merchant" border stripe ref="singleTable" :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column align="center" prop="merchantName" label="回收商">
          </el-table-column>
          <el-table-column align="center" prop="" label="总入库旧机（台）">
            <template v-slot:header>
              <span>总入库旧机（台）:{{intoHouseNum}}个</span>
            </template>
            <el-table-column align="center" prop="intoHouseFxNum" label="入库繁星旧机（台）" sortable="custom" :sort-orders="['descending', 'ascending']">
            </el-table-column>
            <el-table-column align="center" prop="intoHouseFxDefaultNum" label="入库繁星暂存旧机（台）" sortable="custom" :sort-orders="['descending', 'ascending']">
            </el-table-column>
            <el-table-column align="center" prop="intoHouseUnFxNum" label="入库非繁星旧机（台）" sortable="custom" :sort-orders="['descending', 'ascending']">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" prop="" label="当前在库旧机（台）">
            <template v-slot:header>
              <span>当前在库旧机（台）:{{ onHouseNum}}</span>
            </template>
            <el-table-column align="center" prop="onHouseFxNum" label="繁星在库旧机" sortable="custom" :sort-orders="['descending', 'ascending']">
            </el-table-column>
            <el-table-column align="center" prop="onHouseFxDefaultNum" label="繁星暂存在库旧机（台）" sortable="custom" :sort-orders="['descending', 'ascending']">
            </el-table-column>
            <el-table-column align="center" prop="onHouseUnFxNum" label="非繁星在库旧机（台）" sortable="custom" :sort-orders="['descending', 'ascending']">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="seeOldPhone(scope.row)">查看入库旧机</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align:right;margin: 20px 0;" background @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40]" 
          :current-page.sync="table1Page.pageNum" :page-size="table1Page.pageSize" layout="total, prev, pager, next, jumper"
          :total="table1Page.total">
        </el-pagination>
      </div>
      <!-- 表格区 收件包裹-->
      <div v-else>
        <el-table key="parcel" ref="tableList" border stripe :data="tableData2" style="width:100%"
          :row-key="row => row.merchantId" :default-sort="{prop:'allParcelNum',order:'descending'}" @sort-change="sortTable2">
          <el-table-column align="center" type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column align="center" prop="merchantName" label="回收商">
          </el-table-column>
          <el-table-column align="center" prop="allParcelNum" label="总签收包裹（个）" sortable="custom" :sort-orders="['descending', 'ascending']">
          </el-table-column>
          <el-table-column align="center" prop="fxParcelNum" label="繁星包裹数量（个）" sortable="custom" :sort-orders="['descending', 'ascending']">
          </el-table-column>
          <el-table-column align="center" prop="unFxParcelNum" label="非繁星包裹数量（个）" sortable="custom" :sort-orders="['descending', 'ascending']">
          </el-table-column>
        </el-table>
        <el-pagination style="text-align:right;margin: 20px 0;" background @current-change="handleCurrentChange2"
          :page-sizes="[10, 20, 30, 40]" 
          :current-page.sync="table2Page.pageNum" :page-size="table2Page.pageSize" layout="total, prev, pager, next, jumper"
          :total="table2Page.total">
        </el-pagination>
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "class-list",
  components: {
  },
  data() {
    return {
      ackOrDesc:'01',// 降序：01，升序：02
      orderType:'05',//排序类别【入库繁星旧机：01，入库繁星暂存旧机：02，入库非繁星旧机：03，繁星在库旧机：04，繁星暂存在库旧机：05，非繁星在库旧机：06】
      orderType2:'01',//排序类别【总签收包裹：01，繁星包裹数量：02，非繁星包裹数量：03】
      tabType: '01',//'01'入库旧机,'02'收件包裹
      onHouseNum: 0,//当前在库旧机（台）
      intoHouseNum: 0,//总入库旧机（台）
      merchantSelectList: [],
      seachMerchantId: '',//回收商id
      dateType: "01",//今日01/本周03/本月05
      valueTime: "",//时间
      startTime: "",
      endTime: "",
      page: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      tableData: [],//入库旧机表格数据
      tableData2: [],//收件包裹表格数据
      tableColumns2: [
        { label: "回收商", prop: "merchantName" },
        { label: "总签收包裹（个）", prop: "allParcelNum" },
        { label: "繁星包裹数量（个）", prop: "fxParcelNum" },
        { label: "非繁星包裹数量（个）", prop: "unFxParcelNum" },
      ],
      table1Page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      table2Page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      searchLoading: false
    };
  },
  created() {
    this.getMerchantSelectList()
    this.handleCurrentChange()
  },
  watch: {
  },
  methods: {
    btnClick(type) {
      this.tabType = type
      if (type === '01') {
        this.handleCurrentChange(1)
      } else {
        this.handleCurrentChange2(1)
      }
    },
    // 查看入库旧机
    seeOldPhone(row) {
      console.log(row, '2222222222');
      // window.location.href = `/transferManagement/subdistriButor`, "_blank";
      window.open(`/transferManagement/receiptDoc?merchantId=${row.merchantId}&merchantName=${row.merchantName}`);
    },
    // 回收商下拉数据
    getMerchantSelectList() {
      _api.getMerchantSelectList().then((res) => {
        if (res.code === 1) {
          this.merchantSelectList = res.data
        }
      })
    },
    merchanChange() {//下拉选择回收商
      this.seachData()
    },
    //时间选择点击事件
    dataObtain(way) {
      this.dateType = way;
      this.valueTime = ""
      this.startTime = ""
      this.endTime = ""
      if (this.tabType === '01') {
        this.handleCurrentChange(1)
      } else {
        this.handleCurrentChange2(1)
      }
    },
    // 查询
    seachData() {
      if (this.valueTime !== null && this.valueTime) {
        (this.dateType = ""),
          (this.startTime = this.valueTime[0]),
          (this.endTime = this.valueTime[1]);
      } else {
        if (!this.dateType) {
          (this.dateType = "01"), (this.startTime = ""), (this.endTime = "");
        }
      }
      this.searchLoading = true
      if (this.tabType === '01') {
        this.handleCurrentChange(1)
      } else {
        this.handleCurrentChange2(1)
      }
    },
    // 入库旧机排序
    sortTable(column){
      console.log('===',column);
      
      if(column.prop){
        // 排序方式
        if(column.order == 'ascending'){
          this.ackOrDesc = '02'
        }else if(column.order == 'descending'){
          this.ackOrDesc = '01'
        }

        // 排序字段
        switch (column.prop) {
          case 'intoHouseFxNum':
            this.orderType = '01';//入库繁星旧机
            break;
          case 'intoHouseFxDefaultNum':
            this.orderType = '02';//入库繁星暂存旧机
            break;
          case 'intoHouseUnFxNum':
            this.orderType = '03';//入库非繁星旧机
            break;
          case 'onHouseFxNum':
            this.orderType = '04';//繁星在库旧机
            break;
          case 'onHouseFxDefaultNum':
            this.orderType = '05';//繁星暂存在库旧机
            break;
          case 'onHouseUnFxNum':
            this.orderType = '06';//非繁星在库旧机
            break;
          default:
            this.orderType = '05';
        }
      }
      this.handleCurrentChange()
    },
    // 收件包裹排序
    sortTable2(column){
      console.log('===',column);
      
      if(column.prop){
        // 排序方式
        if(column.order == 'ascending'){
          this.ackOrDesc = '02'
        }else if(column.order == 'descending'){
          this.ackOrDesc = '01'
        }

        // 排序字段
        switch (column.prop) {
          case 'allParcelNum':
            this.orderType2 = '01';//总签收包裹
            break;
          case 'fxParcelNum':
            this.orderType2 = '02';//繁星包裹数量
            break;
          case 'unFxParcelNum':
            this.orderType2 = '03';//非繁星包裹数
            break;
          default:
            this.orderType2 = '01';
        }
      }
      this.handleCurrentChange2()
    },
    // 入库旧机表格数据
    handleCurrentChange(val) {
      console.log(val);
      if (val) {
        this.table1Page.pageNum = val;
      } else {
        this.table1Page.pageNum = 1;
      }
      const baseRequest = {
        ackOrDesc:this.ackOrDesc,
        orderType:this.orderType,
        merchantId: this.seachMerchantId,
        dateType: this.dateType,
        endTime: this.endTime,
        // merchantId: this.seachMerchantId,
        pageNum: this.table1Page.pageNum,
        pageSize: this.table1Page.pageSize,
        startTime: this.startTime
      };
      _api.getErpMerchantList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.intoHouseNum = res.data.intoHouseNum;
          this.onHouseNum = res.data.onHouseNum;
          this.tableData = res.data.ipage.records;
          this.table1Page.pageNum = res.data.ipage.current;
          this.table1Page.total = res.data.ipage.total;
        }
      }).finally(() => {
        this.searchLoading = false
      });
    },
    // 收件包裹表格数据
    handleCurrentChange2(val) {
      console.log(val);
      if (val) {
        this.table2Page.pageNum = val;
      } else {
        this.table2Page.pageNum = 1;
      }
      const baseRequest = {
        ackOrDesc:this.ackOrDesc,
        orderType:this.orderType2,
        merchantId: this.seachMerchantId,
        dateType: this.dateType,
        endTime: this.endTime,
        // merchantId: this.seachMerchantId,
        pageNum: this.table2Page.pageNum,
        pageSize: this.table2Page.pageSize,
        startTime: this.startTime
      };
      _api.getErpParcelList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.tableData2 = res.data.records;
          this.table2Page.pageNum = res.data.current;
          this.table2Page.total = res.data.total;
        }
        this.searchLoading = false
      }).finally(() => {
        this.searchLoading = false
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
/deep/.el-table--border th.el-table__cell {
  border-bottom: 1px solid #c4d7fd !important;
}

.top_swith {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 切换
.button_top {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faultBtn {
  width: 240px;
  height: 42px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;

  .left {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 40px;
    height: 40px;
    background: #0981ff;
    border-radius: 20px 0 0 20px;
  }

  .left2 {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 40px;
    height: 40px;
    background: #0981ff;
    border-radius: 0 20px 20px 0;
  }

  .right {
    text-align: center;
    color: #333;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 40px;
    height: 40px;
    border-radius: 20px;
  }
}

.btnStore {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 22px 0;

  .time-select {
    display: flex;

    .btn {
      box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
      margin: 0px 10px 0px 0;
      text-align: center;
      cursor: pointer;
    }

    .btnType-after2 {
      // width: 33%;
      width: 80px;
      min-width: max-content;
      height: 40px;
      background: #ffffff;
      color: #0981ff;
      line-height: 40px;
      border-radius: 20px;
    }

    .btnType2 {
      width: 80px;
      min-width: max-content;
      height: 40px;
      background: #0981ff;
      color: white;
      line-height: 40px;
      border-radius: 20px;
    }
  }
}
</style>
